import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/templates/single-page.js";
import { graphql } from 'gatsby';
export const data = graphql`
  query {
    outdoorStories: file(relativePath: { eq: "outdoorstories.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pumpkinFace: file(relativePath: { eq: "pumpkinface.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sunglasses: file(relativePath: { eq: "sunglasses.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cars: file(relativePath: { eq: "cars.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Img = makeShortcode("Img");
const layoutProps = {
  data,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Img fluid={props.data.outdoorStories.childImageSharp.fluid} alt="Miss Marianne reads to the Creative Play class outside." style={{
      marginBottom: '1.75rem'
    }} mdxType="Img" />
    <h2>{`Creative Play Class`}</h2>
    <p><strong parentName="p">{`Goal`}</strong>{`: To provide a positive, enjoyable first school experience, establishing a healthy foundation for future school years. For some children, this may encompass an individualized approach to easing separation anxiety.`}</p>
    <p><strong parentName="p">{`Age`}</strong>{`:                           Children 2.5yrs to 3yrs`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Days`}</strong>{`:                          Monday - Friday`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Time`}</strong>{`:                          9:00a - 12:00p`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Lunch`}</strong>{`:                         12:00p - 12:30p (optional opt-in)`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Number of Students`}</strong>{`:            Capped at 9`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Co-op Requirement`}</strong>{`:             Varies based on class size  `}</p>
    <p>{`Creative Play creates an environment for young children to explore, learn, and grow through hands-on play. Guided by an experienced early childhood teacher, children aged 2.5 to 3 have the opportunity to play with a variety of age-appropriate materials, participate in a peer group, discover new activities, test new ideas, and practice new concepts such as social interaction and using language to communicate with peers. The class introduces a balanced structure (replicated in other Co-op classes) of group activity (music activities, story time, dramatic role-playing), snack time, free play (varied art activities, activities for fine motor development), and outdoor play. Parents are encouraged to stay in the classroom for as long as their children need them to ease separation anxiety. For many children, this is their first school experience, and we want them to feel comfortable, allowing their natural enthusiasm to flourish. A happy, successful school experience at this age paves the way for positive feelings about school.`}</p>
    <hr></hr>
    <Img fluid={props.data.pumpkinFace.childImageSharp.fluid} alt="A student holds a Jack-O-Lantern craft over her face." style={{
      marginBottom: '1.75rem'
    }} mdxType="Img" />
    <h2>{`Discovery/Pre-K (DPK) AM`}</h2>
    <p><strong parentName="p">{`Goal`}</strong>{`: To address the important emotional, social, physical, and intellectual issues that are critical to a preschooler's development, while encouraging each child's thinking, language, creativity, and problem-solving ability through developmentally-appropriate activities`}</p>
    <p><strong parentName="p">{`Age`}</strong>{`:                           Children 3yrs - 5yrs`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Days`}</strong>{`:                          Monday - Friday`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Time`}</strong>{`:                          9:00a - 12:30p (includes lunch)`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Number of Students`}</strong>{`:            Capped at 20`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Co-op Requirement`}</strong>{`:             Once every 4-6 weeks, depending on enrollment`}</p>
    <p>{`In 2014, we combined our Discovery class for 3-year-olds and our Pre-K class for 4-year-olds into a single multi-age class, offering a full, five-day program for children ages 3 to 5 years old. The multi-age classroom is used widely in preschool settings for this age group and research shows that multi-age groups offer benefits to both the younger and older children. Younger children learn from their older peers and aspire to master the skills demonstrated by the older children. Older children get opportunities to lead and teach their younger counterparts while simultaneously reinforcing their own abilities.`}</p>
    <p>{`For our three-year old students, this class also provides a valuable introduction to the school for first-time students and a natural progression for Creative Play alumni. For our 4-year-old students, the Pre-K curriculum is a rich one, preparing the child for Kindergarten and future school years. In addition, our morning DPK program may be combined with our afternoon DPK class to give your child a full-day school experience five days a week. See "DPK PM" below for details.`}</p>
    <p>{`Certified teachers Christi Porter-Johnson and Anneliese Scherfen co-teach this class and will tailor activities throughout the `}<a parentName="p" {...{
        "href": "dpk-curriculum"
      }}>{`curriculum content areas`}</a>{` for the needs of each child based on age appropriate goals and individual child development. Each subject unit throughout the year - for example, studying the seashore - recognizes and encourages the linked `}<a parentName="p" {...{
        "href": "objectives"
      }}>{`growth objectives`}</a>{` through various activities.`}</p>
    <hr></hr>
    <Img fluid={props.data.sunglasses.childImageSharp.fluid} alt="A group of Co-op students wearing sunglasses and playing with PlayDoh." style={{
      marginBottom: '1.75rem'
    }} mdxType="Img" />
    <h2>{`Discovery/Pre-K (DPK) PM`}</h2>
    <p><strong parentName="p">{`Age`}</strong>{`:                           Children 3yrs - 5yrs`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Days`}</strong>{`:                          Monday - Friday`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Time`}</strong>{`:                          12:00p - 2:45pm (includes lunch)`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Number of Students`}</strong>{`:            Capped at 15`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Co-op Requirement`}</strong>{`:             None`}</p>
    <p>{`This class offers children attending a morning Pre-K or Kindergarten program (at the Co-op or elsewhere) an opportunity to experience a full school day. It can also be a standalone Pre-K class for families who prefer an afternoon schedule. Goals are the same as in DPK AM, above.`}</p>
    <p>{`Because this program is non-co-oping, it's a great opportunity for children who are already enrolled in our Co-op to make the transition from parents in the classroom to teachers only in preparation for Kindergarten. The program explores monthly themes through the use of literature, math and science activities, cooking, art, and music and movement. Themes may include harvest time, Native Americans, weather, bears, etc. The program consists of outdoor play, quiet reading time, circle time, a small group activity, indoor play time, and snack time.`}</p>
    <hr></hr>
    <Img fluid={props.data.cars.childImageSharp.fluid} alt="Two Creative Play students play with cars." style={{
      marginBottom: '1.75rem'
    }} mdxType="Img" />
    <h2>{`Lunch Program`}</h2>
    <p>{`An optional half-hour lunch program is available to children in the Creative Play class. Lunch is included as part of the DPK program. Children bring their own lunches and are supervised by one or more teacher's assistants.`}</p>
    <p>{`There are several advantages to the lunch program. Children enrolled in half-day classes are provided another opportunity to practice self-help skills in a relaxed, social atmosphere where they can share and communicate feelings and ideas with schoolmates. And, of course, for children enrolled in a full day of classes, the lunch program provides the transition to the afternoon's activities.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      